/* .container-skills {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 100px;
    position: relative;
}

.container-skills h2 {
    font-size: 1.8rem;
    position: relative;
}

.container-skills h2::after {
    content: "";
    display: block;
    width: 50%;
    height: 0.2em;
    position: absolute;
    bottom: -5px;
    left: 25%;
    background-color: #23b3da;
}

.skills-list {
    max-width: 900px;
    width: 70%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 30px;
}

.skills-list-item {
    margin: 0.5em;
    padding: 1em;
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 8%;
    position: relative; 
    overflow: hidden; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
}

.skills-list-item::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%; 
    transform: translateX(-50%);
    width: 0%;
    height: 0.3em;
    background-color: #23b3da; 
    transition: width 0.4s ease; 
}

.skills-list-item:hover::after {
    width: 100%; /
}

.skills-list-item img {
    width: 50px;
    height: 50px;
    object-fit: cover;
}

.skills-list-item span {
    margin-top: 0.5em;
    text-align: center;
    white-space: nowrap; 
}

*/

.container-skills {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 100px;
    position: relative;
}

.container-skills h2 {
    font-size: 1.8rem;
    position: relative;
}

.container-skills h2::after {
    content: "";
    display: block;
    width: 50%;
    height: 0.2em;
    position: absolute;
    bottom: -5px;
    left: 25%;
    background-color: #23b3da;
}

.skills-list {
    max-width: 900px;
    width: 70%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
}

.skills-list-item {
    margin: 0.5em;
    padding: 1em;
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.4s ease;
    width: 8%;
    min-width: 80px;
}

.skills-list-item img {
    width: 50px;
    height: 50px;
    object-fit: cover;
}

.skills-list-item span {
    margin-top: 0.5em;
    text-align: center;
    white-space: nowrap;
}
.skills-list-item:hover {
    transform: translateY(-5px);
}

.dark-mode .skills-list-item {
    background-color: rgba(54, 54, 54, 0.274);
    color: white;
    box-shadow: 2px 4px 8px rgba(41, 89, 105, 0.63); /* Adiciona sombreamento */
}

@media screen and (max-width: 645px) {
    .skills-list {
        flex-direction: row;
        align-items: center;
    }

    .skills-list-item {
        width: 30%;
        margin-bottom: 1em; /* Adicionar espaço entre os itens */
    }
}
