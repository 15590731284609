.about-me-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1100px; /* Ajuste conforme necessário */
    margin: 0 auto;
    width: 90%;
    height: calc(100vh - 210px);
}

.about-me-left {
    text-align: start;
}

.about-me-right {
    margin-left: 15%;
    font-size: 1em;
}

.about-me-left,
.about-me-right {
    width: 45%; /* Cada div ocupa metade do container */
    text-align: start;
}

.about-me {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 550px; /* Ajuste para metade do container principal */
}

.about-me span {
    color: #23b3da;
}

.about-me-left h2 {
    font-size: 5rem;
}

.about-me h3 {
    font-size: 1.7rem;
}

.ul-icons {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-top: 1.2em;
}

.ul-icons ul {
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon-link {
    position: relative; /* Necessário para o posicionamento do ::before */
    display: inline-block;
}
.matheusColour {
    color: #23b3da;
}
.icon-link::before {
    content: "";
    display: inline;
    width: 0%;
    height: 0.2em;
    position: absolute;
    bottom: -5px; /* Ajusta a posição vertical */
    background-color: #23b3da;
    transition: width 0.25s ease-in;
}

.icon-link:hover::before {
    width: 100%;
}

.icon-link img {
    transition: filter 0.25s ease-in-out; /* Adiciona uma transição suave */
}

.icon-link:hover img {
    filter: brightness(0) saturate(100%) hue-rotate(195deg); /* Transforma o cinza em azul */
}

.get-in-touch-btn {
    background-color: #23b3da;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 25px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 10px;
}

.get-in-touch-btn:hover {
    background-color: #1a91ba;
}

/* Estilos padrão para o about-me */
.about-me-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1100px;
    margin: 0 auto;
}

.about-me-left,
.about-me-right {
    width: 45%;
    text-align: start;
}

.about-me-left h2 {
    font-size: 5rem;
}

.about-me h3 {
    font-size: 1.7rem;
}

.linkedinIcon {
    display: none;
}

.githubIcon {
    display: none;
}

.App.dark-mode .linkedinIcon,
.App.dark-mode .githubIcon {
    filter: invert(1);
}

@media screen and (max-width: 645px) {
    .about-me-container {
        display: flex;
        flex-direction: column;
        height: calc(100vh - 270px);
    }

    .about-me-left,
    .about-me-right {
        width: 100%;
        text-align: center;
        margin-bottom: 1.5em;
        margin: 0 auto;
    }

    .about-me-left h2 {
        font-size: 3rem;
    }

    .about-me-right h3 {
        font-size: 1.2rem;
    }

    .get-in-touch-btn {
        display: none;
        font-size: 0.9rem;
        margin-top: 1em;
    }

    .linkedinIcon {
        display: block;
    }

    .githubIcon {
        display: block;
        width: 26px;
        height: 26px;
    }

    .iconsMobile {
        display: flex;
        justify-content: center;
        gap: 5px;
    }
}
