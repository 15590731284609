.contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 80px;
    position: relative;
}

h2 {
    font-size: 2rem;
    margin-bottom: 40px;
    position: relative;
}

.btn-container {
    display: flex;
    justify-content: center; /* Centraliza os botões horizontalmente */
    align-items: center; /* Centraliza os botões verticalmente */
}

.contact-container h2::after {
    content: "";
    display: block;
    width: 50%; /* Largura do traço */
    height: 0.2em;
    position: absolute;
    bottom: -5px; /* Ajusta a posição vertical */
    left: 25%; /* Posiciona o traço no meio */
    background-color: #23b3da;
}

.contact-button {
    padding: 10px 25px;
    font-size: 1rem;
    background-color: white;
    border: 2px solid #23b3da;
    color: #23b3da;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 20px;
    border-radius: 35px;
}

.contact-button:first-child {
    margin-right: 30px;
}
.contact-button:last-child {
    margin-left: 30px;
}

.contact-button-mobile:hover,
.contact-button:hover {
    background-color: #23b3da;
    color: white;
}

.dark-mode .contact-button-mobile,
.dark-mode .contact-button {
    background-color: #23b3da;
    color: white;
}

.dark-mode .contact-button-mobile:hover .dark-mode .contact-button:hover {
    background-color: #1a91ba;
    color: white;
}

.contactMobile {
    display: none;
}

@media screen and (max-width: 645px) {
    .contact-container {
        margin-bottom: 70px;
    }
    .btn-container {
        display: none;
    }

    .contactMobile {
        display: block;
    }

    .contact-button-mobile {
        padding: 10px 25px;
        font-size: 1rem;
        background-color: white;
        border: 2px solid #23b3da;
        color: #23b3da;
        cursor: pointer;
        transition: background-color 0.3s ease;
        margin-top: 30px;
    }
}
