.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    width: 95%;
    max-width: 1100px;
    height: 8em;
}

.header p {
    font-weight: bold;
}

.header span {
    color: #23b3da;
}
.logo-link {
    text-decoration: none;
    color: inherit;
}

.logo-link:hover {
    color: inherit;
}

.navbar-ul {
    display: flex;
    margin-right: 1.5em;
    margin: 0;
    padding: 0;
}

.navbar-ul li {
    list-style: none;
    margin-left: 1.5em;
}

.navbar-ul li a {
    position: relative;
    color: inherit;
    text-decoration: none;
}

.navbar-ul li a::before {
    content: "";
    display: inline;
    width: 0%;
    height: 0.2em;
    position: absolute;
    bottom: -5px;
    background-color: #23b3da;
    transition: width 0.25s ease-in;
}

.navbar-ul li a:hover::before {
    width: 100%;
}

.navbar-ul li a:hover {
    color: #23b3da;
    cursor: pointer;
}

.navbar-ul li button {
    display: inline-block;
    list-style: none;
    position: relative;
    color: inherit;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
    margin: 0;
    padding: 0;
}

.navbar-ul li button::before {
    content: "";
    display: inline;
    width: 0%;
    height: 0.2em;
    position: absolute;
    bottom: -5px;
    background-color: #23b3da;
    transition: width 0.25s ease-in;
}

.navbar-ul li button:hover::before {
    width: 100%;
}

.navbar-ul li button:hover {
    color: #23b3da;
}

.nome-initials {
    display: none;
}

.lightModeMobileBtn {
    display: none;
}

.darkModeMobileBtn {
    display: none;
}

.headerMobile {
    display: none;
}

.btnFechar {
    display: none;
}

@media screen and (max-width: 645px) {
    .headerMobile {
        gap: 5px;
    }
    .logo-link {
        font-size: 1.7em;
        margin-left: 15px;
    }
    .nome-full {
        display: none;
    }
    .nome-initials {
        display: inline;
    }

    .navbar-ul {
        display: none;
    }

    .navbar-ul {
        display: none;
        flex-direction: column;
        position: fixed;
        align-items: center;
        justify-content: center;
        gap: 50px;
        top: 0;
        left: 0;
        background-color: #ffffff;
        padding: 10px;
        width: 100%;
        height: 100%;
        z-index: 9999;
        background-color: rgba(255, 255, 255);
    }

    .navbar-ul .btnLinguagem,
    .navbar-ul .btnDarkMode {
        display: none;
    }
    .navbar-ul.open {
        display: flex;
        overflow-y: hidden;
    }

    .navbar-ul li {
        margin: 5px 0;
    }

    .headerMobile {
        display: flex;
    }
    .headerMobile img {
        cursor: pointer;
    }

    .lightModeMobileBtn {
        display: block;
        margin-right: 15px;
    }

    .darkModeMobileBtn {
        display: block;
        margin-right: 15px;
    }

    .headerMobile img {
        margin: 0;
        padding: 0;
        width: 40px;
        height: 40px;
    }

    .btnFechar {
        display: block;
        cursor: pointer;
        width: 30px;
        height: 30px;
    }

    .App.dark-mode .navbar-ul {
        background-color: black; /* Cor de fundo do modal para o modo escuro */
        color: #fff; /* Cor do texto no modal para o modo escuro */
    }
}
