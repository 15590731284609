.div-projects {
    text-align: center;
    margin: 50px 0;
    position: relative;
}

.div-projects h2 {
    font-size: 1.8rem;
    position: relative;
    display: inline-block;
}

.div-projects h2::after {
    content: "";
    display: block;
    width: 50%;
    height: 0.2em;
    position: absolute;
    bottom: -5px; /* Ajusta a posição vertical */
    left: 25%; /* Posiciona o traço no meio */
    background-color: #23b3da;
    transition: width 0.25s ease-in;
}

.projects {
    margin: 0 auto;
    width: 75%;
    border-color: white;
}

/* .project-card {
    width: 300px;
    padding: 20px;
    margin: 20px 20px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex; 
    transition: transform 0.4s ease;
    flex-direction: column; 
} */

.project-card {
    display: flex;
    height: auto;
    width: 80%;
    max-height: 400px;
    transition: transform 0.4s ease;
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 0;
}

.project-card h3 {
    height: 10%;
    margin: 0 auto;
}
.project-card-description {
    height: 10vh;
    width: 92%;
    margin: auto;
    margin-top: 25px;
    margin-bottom: 20px;
}

.project-card:hover {
    transform: translateY(-5px);
}

.technologies {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
}

.technologies p {
    background-color: rgba(171, 182, 185, 0.423); /* Azul clarinho transparente */
    color: rgb(0, 0, 0); /* Azul escuro */
    padding: 8px 16px; /* Reduza o padding para diminuir o tamanho das habilidades */
    border-radius: 25px; /* Adiciona um border-radius para arredondar as bordas */
    margin-bottom: 5px; /* Adiciona um espaçamento inferior entre os itens */
    margin-right: 5px; /* Adiciona um espaçamento direito entre os itens */
    white-space: nowrap; /* Impede que as habilidades quebrem a linha */
    font-size: 0.71rem; /* Reduza o tamanho da fonte */
    font-weight: bold;
}

.icons img {
    width: 25px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.icon-link {
    position: relative; /* Necessário para o posicionamento do ::before */
    display: inline-block;
    margin-top: auto; /* Move o ícone para a parte inferior do card */
}

.icon-link::before {
    content: "";
    display: inline;
    width: 0%;
    height: 0.2em;
    position: absolute;
    bottom: -10px; /* Ajusta a posição vertical para alinhar com o ícone */
    left: 0; /* Posiciona o hover azul desde o início do ícone */
    background-color: #23b3da;
    transition: width 0.25s ease-in;
}

.icon-link:hover::before {
    width: 100%;
}

.icon-link img {
    transition: filter 0.25s ease-in-out; /* Adiciona uma transição suave */
}

.icon-link:hover img {
    filter: brightness(1) saturate(100%) hue-rotate(195deg); /* Transforma o cinza em azul */
}

.dark-mode .icons img {
    width: 25px;
    margin-top: 10px; /* Ajuste necessário */
    margin-bottom: 10px;
}

/* .dark-mode .project-card {
    background-color: rgba(54, 54, 54, 0.274);
    color: white;
    box-shadow: 2px 4px 8px rgba(41, 89, 105, 0.63); 
} */

.dark-mode .slick-dots li button:before {
    color: white; /* Cor dos dots no modo escuro */
}

.dark-mode .slick-dots li.slick-active button:before {
    color: #23b3da; /* Cor desejada para o dot selecionado */
}

.prev-arrow,
.next-arrow {
    display: none;
}

@media screen and (max-width: 942px) {
    .projects {
        flex-direction: column;
        align-items: center;
    }

    .project-card-description {
        height: 12vh;
    }
}

@media screen and (max-width: 805px) {
    .projects {
        flex-direction: column;
        align-items: center;
    }

    .project-card-description {
        height: 7vh;
    }

    .technologies {
        margin-top: 8vh;
    }
}

@media screen and (max-width: 450px) {
    .projects {
        flex-direction: column;
        align-items: center;
    }

    .technologies {
        margin-top: 12vh;
    }
}
